import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CommonButton } from "../../components/Buttons";
import { LayoutCard } from "../../components/Cards";
import TextEditor from "../../components/TextEditor";
import {
  asyncCreatePrivacyPolicy,
  asyncGetPrivacyPolicy,
  asyncUpdatePrivacyPolicy,
} from "../../store/Users/UsersAsync";

const Privacy = () => {
  const {
    register,
    setValue,
    handleSubmit,
    // reset,
    formState: { errors },
    getValues,
  } = useForm();

  const dispatch = useDispatch();
  const { policy } = useSelector((s) => s.users);
  const [loader, setLoader] = useState(true);

  const loadData = useCallback(async () => {
    await dispatch(asyncGetPrivacyPolicy()).unwrap();
    setLoader(false);
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);

  useEffect(() => {
    setValue("description", policy.description);
  }, [setValue, policy.description]);

  const onSubmit = async (body) => {
    await dispatch(asyncCreatePrivacyPolicy({ body })).unwrap();
    // reset();
  };

  const onUpdate = async (body) => {
    if (body.description !== policy.description) {
      await dispatch(
        asyncUpdatePrivacyPolicy({ ...body, id: policy._id })
      ).unwrap();
    }
    // reset();
  };

  return (
    <LayoutCard>
      <form onSubmit={handleSubmit(policy.description ? onUpdate : onSubmit)}>
        {!loader && (
          <TextEditor
            name="description"
            value={policy.description}
            setValue={setValue}
            {...register("description", {
              required: "Description is required",
            })}
            errors={errors}
            label="Enter Privicy Policy"

          />
        )}
        <CommonButton
          htmlType="submit"
          title={getValues().description ? "Update" : "Submit"}
          style={{ marginTop: 20 }}
        />
      </form>
    </LayoutCard>
  );
};
export default Privacy;
