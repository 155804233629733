import React from 'react';
import PrivacyPolicyCom from './ppComp';

function PrivacyPolicy() {
  return (
    <div>
      <PrivacyPolicyCom />
    </div>
  );
}

export default PrivacyPolicy;
