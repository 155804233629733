// import { convertToHTML } from "draft-convert";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from "./texteditor.module.scss";

const TextEditor = ({ name, value, setValue, errors , label }) => {
  const contentBlock = convertFromHTML(value ?? "");
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setValue("description", html);
  };

  return (
    <div className={styles.text_editor}>
      <p className={styles.lable}>{label}</p>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName={styles.wrapper}
        editorClassName={styles.editor}
      />
      {errors[name] && <span>{errors[name].message}</span>}
    </div>
  );
  // }
};
export default TextEditor;
