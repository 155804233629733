import React from "react";
import { Modal, Button, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "../../components/Table/table.module.scss";
import { BsFillTrashFill } from "react-icons/bs";
import {
  asyncDeleteUserById,
  asyncGetActiveUsers,
  asyncGetAllAdmins,
  asyncGetFreezeUsers,
} from "../../store/Users/UsersAsync";
import { useDispatch } from "react-redux";

const ShowModal = ({ data }) => {
  const { confirm } = Modal;
  const { status, id, role } = data;
  const dispatch = useDispatch();
  function showPromiseConfirm(id) {
    confirm({
      title: "Do you want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        return new Promise((resolve, reject) => {
          if (id) resolve();
          else reject();
        })
          .then(async () => {
            await dispatch(asyncDeleteUserById({ id }));
            status === "active" && role === "user"
              ? dispatch(asyncGetActiveUsers())
              : dispatch(asyncGetFreezeUsers());
          })
          .then(() => role !== "user" && dispatch(asyncGetAllAdmins()))
          .catch(() => console.log("Oops errors!"));
      },
      onCancel() { },
    });
  }

  return (
    <a className={styles.bttns} onClick={() => showPromiseConfirm(id)}>
      {" "}
      <BsFillTrashFill className={styles.icon} />
    </a>
  );
};

export default ShowModal;
