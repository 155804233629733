import { GetColumnSearchProps } from "../../components/Table/search";
import styles from "../../components/Table/table.module.scss";

import { Col, Modal, Row, Table } from "antd";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAdvertisment,
  getAllAdvertisments,
} from "../../store/Advertisement/AdvertismentAsync";
import { allAddvertismentObject } from "../../store/Advertisement/AdvertismentSlice";
import { BASE_URL } from "../../store/api";
import { BsFillTrashFill } from "react-icons/bs";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const Advertisement = () => {
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const datae = useSelector(allAddvertismentObject);
  useEffect(() => {
    dispatch(getAllAdvertisments());
  }, [dispatch]);
  const handleDelete = useCallback(
    async (id) => {
      confirm({
        title: "Do you want to delete this advertisement?",
        icon: <ExclamationCircleOutlined />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          return new Promise((resolve, reject) => {
            if (id) resolve();
            else reject();
          })
            .then(async () => {
              await dispatch(deleteAdvertisment(id));
              console.log("deleted");
            })
            .catch(() => console.log("Oops errors!"));
        },
        onCancel() { },
      });
    },
    [dispatch, confirm]
  );
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (serial, id, index) => index + 1,
      ...GetColumnSearchProps("id"),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, { title }) => title,
      ...GetColumnSearchProps("title"),
    },
    // {
    //   title: "Ads Level",
    //   dataIndex: "adsLevel",
    //   key: "adsLevel",
    //   render: (_, { adsLevel }) => adsLevel,
    //   ...GetColumnSearchProps("adsLevel"),
    // },
    {
      title: "View Count",
      dataIndex: "viewsCount",
      key: "viewsCount",
      render: (_, { viewsCount }) => viewsCount,
      ...GetColumnSearchProps("adsLevel"),
    },
    {
      title: "Ads File",
      dataIndex: "Ads File Type",
      // key: "uploadFile",
      render: (_, { uploadFile }) =>
        `${uploadFile?.fileType} / Size : ${(
          uploadFile?.fileSize / 1024
        ).toFixed(2)}kb`,
      // ...GetColumnSearchProps("uploadFile"),
    },
    {
      title: "Ads File value",
      dataIndex: "Ads File Type",
      // key: "uploadFile",
      render: (_, { uploadFile }) => (
        <a
          href={`${BASE_URL}files-upload/${uploadFile.path}`}
          target="_blank"
          rel="noreferrer"
        >{`${BASE_URL}files-upload/${uploadFile.path}`}</a>
      ),
      // ...GetColumnSearchProps("uploadFile"),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: 100,
      render: (_, { _id }) => {
        return (
          <div className={styles.action_bttns}>
            <div className={styles.bttns} onClick={() => handleDelete(_id)}>
              {" "}
              <BsFillTrashFill className={styles.icon} />
            </div>
          </div>
        );
      },
    },
  ];
  const data = Object.values(datae);
  console.log(datae, "my data");
  return (
    <>
      <Row className={styles.home_row}>
        <Col span={24}>
          <Table
            columns={columns}
            expandIconColumnIndex={12}
            dataSource={data}
            rowKey={(record) => record._id}
          />
        </Col>
      </Row>
    </>
  );
};
export default Advertisement;
