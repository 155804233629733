import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { notification } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

export const asyncShowSuccess = createAsyncThunk(
  "success",
  async (data, { dispatch }) => {
    if (data) {
      const { message, description } = data;
      const args = {
        message,
        description,
        duration: 4.5,
        icon: <CheckCircleOutlined style={{ color: "green" }} />,
        placement: "topLeft",
      };
      notification.open(args);
    }
  }
);
export const asyncShowError = createAsyncThunk(
  "error",
  async (data, { dispatch }) => {
    if (data) {
      const { message, description } = data;
      const args = {
        message,
        description,
        placement: "topLeft",
        duration: 4.5,
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      };
      notification.open(args);
    }
  }
);

export const usersSlice = createSlice({
  name: "Users",
  initialState: {
    userActive: {},
    userFreeze: {},
    allAdmins: {},
    policy: {},
    conditions: {}
  },
  reducers: {
    setUserActive: (state, { payload }) => {
      state.userActive = payload?.reduce(
        (acc, user) => ({
          ...acc,
          [user.id + "user"]: user,
        }),
        {}
      );
    },
    addActiveUser: (state, { payload }) => {
      const { id } = payload;
      delete state.userFreeze[id + "user"];
      if (state.userActive[id + "user"]) {
        state.userActive[id + "user"] = payload;
        return;
      }
      state.userActive = {
        [id + "user"]: payload,
        ...state.userActive,
      };
    },
    setUserFreeze: (state, { payload }) => {
      state.userFreeze = payload?.reduce(
        (acc, user) => ({
          ...acc,
          [user.id + "user"]: user,
        }),
        {}
      );
    },
    addFreezeUser: (state, { payload }) => {
      delete state.userActive[payload.id + "user"];
      const { id } = payload;
      if (state.userFreeze[id + "user"]) {
        state.userFreeze[id + "user"] = payload;
        return;
      }
      state.userFreeze = {
        [id + "user"]: payload,
        ...state.userFreeze,
      };
    },

    setAllAdmins: (state, { payload }) => {
      state.allAdmins = payload?.reduce(
        (acc, admin) => ({
          ...acc,
          [admin.id + "admin"]: admin,
        }),
        {}
      );
    },
    addandUpdateAdmin: (state, { payload }) => {
      const { id } = payload;
      if (state.allAdmins[id + "admin"]) {
        state.allAdmins[id + "admin"] = payload;
        return;
      }
      state.allAdmins = {
        [id + "admin"]: payload,
        ...state.allAdmins,
      };
    },
    setPolicy: (state, { payload }) => ({ ...state, policy: payload }),
    setConditions: (state, { payload }) => ({ ...state, conditions: payload }),
  },
});

export const {
  setAllAdmins,
  setPolicy,
  setUserActive,
  setConditions,
  setUserFreeze,
  addActiveUser,
  addFreezeUser,
  addandUpdateAdmin,
} = usersSlice.actions;
export default usersSlice.reducer;

export const activeUserObject = createDraftSafeSelector(
  [(state) => state.users.userActive],
  (user) => user
);
export const freezUserObject = createDraftSafeSelector(
  [(state) => state.users.userFreeze],
  (user) => user
);
export const selectUserActive = createDraftSafeSelector(
  [activeUserObject],
  (user) => Object.values(user)
);
export const selectUserFeeze = createDraftSafeSelector(
  [freezUserObject],
  (user) => Object.values(user)
);
export const selectAllAdmin = createDraftSafeSelector(
  [(state) => state.users.allAdmins],
  (admin) => Object.values(admin)
);
