import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CommonButton } from "../../components/Buttons";
import { LayoutCard } from "../../components/Cards";
import TextEditor from "../../components/TextEditor";
import {
    asyncCreateTermsAndConditions,
    asyncGetTermsAndConditions,
    asyncUpdateTermsAndConditions,
} from "../../store/Users/UsersAsync";

const TermsConditions = () => {
    const {
        register,
        setValue,
        handleSubmit,
        // reset,
        formState: { errors },
        getValues,
    } = useForm();

    const dispatch = useDispatch();
    const { conditions } = useSelector((s) => s.users);
    const [loader, setLoader] = useState(true);

    const loadData = useCallback(async () => {
        await dispatch(asyncGetTermsAndConditions()).unwrap();
        setLoader(false);
    }, [dispatch]);

    useEffect(() => {
        loadData();
    }, [dispatch, loadData]);

    useEffect(() => {
        setValue("description", conditions.description);
    }, [setValue, conditions.description]);

    const onSubmit = async (body) => {
        await dispatch(asyncCreateTermsAndConditions({ body })).unwrap();
        // reset();
    };

    const onUpdate = async (body) => {
        if (body.description !== conditions.description) {
            await dispatch(
                asyncUpdateTermsAndConditions({ ...body, id: conditions._id })
            ).unwrap();
        }
        // reset();
    };

    return (
        <LayoutCard>
            <form onSubmit={handleSubmit(conditions.description ? onUpdate : onSubmit)}>
                {!loader && (
                    <TextEditor
                        name="description"
                        value={conditions.description}
                        setValue={setValue}
                        {...register("description", {
                            required: "Description is required",
                        })}
                        errors={errors}
                        label="Enter Terms And Conditions"
                    />
                )}
                <CommonButton
                    htmlType="submit"
                    title={getValues().description ? "Update" : "Submit"}
                    style={{ marginTop: 20 }}
                />
            </form>
        </LayoutCard>
    );
};
export default TermsConditions;
