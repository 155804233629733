import { Col, Layout, Menu, Row } from "antd";
import React, { useEffect } from "react";
import { GoChevronRight } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import grid from "../../assets/icons/grid.svg";
import logo from "../../assets/icons/logo.svg";
import { logout } from "../../store/Auth/AuthSlice";
import { asyncGetDashbaord } from "../../store/Pins/PinsAsync";
import { CategoryCard } from "../Cards";
import styles from "./layout.module.scss";
import classNames from "classnames";

const { Sider, Content } = Layout;
const { SubMenu } = Menu;
const PageLayout = ({ children }) => {
  const { pins, draftPins, users, recentPins, recentDraftPins, recentUsers } =
    useSelector((s) => s.pins);
  const { user } = useSelector((s) => s.login);
  const dispatch = useDispatch();
  // console.log(pins, draftPins, users, recentPins, recentDraftPins, recentUsers  , 'my dashboard')
  useEffect(() => {
    dispatch(asyncGetDashbaord({ type: "superadmin" }));
  }, [dispatch]);
  const data = [
    {
      title: "Total Pins",
      value: pins,
      desc: `recently added ${recentPins}`,
      location: true,
    },
    {
      title: "Draft Pins",
      value: draftPins,
      desc: `recently added ${recentDraftPins}`,
      pin: true,
    },
    {
      title: "Users",
      value: users,
      desc: `recently added ${recentUsers}`,
      user: true,
    },
  ];
  const location = useLocation();
  // console.log(location)
  let isCards = false;
  if (location.pathname === "/") {
    isCards = true;
  }
  const currentPaths = location.pathname.split("/").filter((i) => !!i.trim());
  const handleLogout = () => {
    dispatch(logout());
  };
  // const pageTitle = currentPaths.length>=1 ? currentPaths[0]: ""
  // const activePage  = currentPaths.length>=2?
  // console.log(currentPaths)
  return (
    <Layout>
      <Sider>
        <div className={styles.side_layout}>
          <div className={styles.logo_div}>
            <img src={logo} alt="logo" className={styles.logo} />
            <p>Logo</p>
          </div>
          <div className={classNames(styles.sidebar, "sidebar")}>
            <Menu mode="inline" defaultSelectedKeys={["1"]}>
              <Menu.Item
                className="cstm-menu-item"
                key="1"
                icon={<img src={grid} alt="grid" className="side-icon" />}
              >
                <Link className="sub_links" to="/">
                  Dashboard
                </Link>
              </Menu.Item>
              <SubMenu
                key="sub2"
                icon={<img src={grid} alt="grid" className="side-icon" />}
                title="Pins"
              >
                <Menu.Item key="2">
                  <Link className="submenu-links" to="/pins/active">
                    Active
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link className="submenu-links" to="/pins/hide">
                    Hide
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="4"><Link className='submenu-links' to="/pins/draft">Drafts</Link></Menu.Item> */}
              </SubMenu>
              <SubMenu
                key="sub3"
                icon={<img src={grid} alt="grid" className="side-icon" />}
                title="Users"
              >
                <Menu.Item key="5">
                  <Link className="submenu-links" to="/users/active">
                    Active
                  </Link>
                </Menu.Item>
                <Menu.Item key="6">
                  <Link className="submenu-links" to="/users/freez">
                    Freez
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="sub4"
                icon={<img src={grid} alt="grid" className="side-icon" />}
                title="Admin Users"
              >
                <Menu.Item key="8">
                  <Link className="submenu-links" to="/admin/create">
                    Create Role
                  </Link>
                </Menu.Item>
                <Menu.Item key="9">
                  <Link className="submenu-links" to="/admin/users">
                    All Users
                  </Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                className="cstm-menu-item"
                key="10"
                icon={<img src={grid} alt="grid" className="side-icon" />}
              >
                <Link className="sub_links" to="/emails">
                  Emails
                </Link>
              </Menu.Item>
              <SubMenu
                key="sub5"
                title="Advertisement"
                icon={<img src={grid} alt="grid" className="side-icon" />}
              >
                <Menu.Item key="8">
                  <Link className="submenu-links" to="/advertisement/add">
                    Add Advertisement
                  </Link>
                </Menu.Item>
                <Menu.Item key="11">
                  <Link className="submenu-links" to="/advertisement">
                    All Advertisement
                  </Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                className="cstm-menu-item"
                key="12"
                icon={<img src={grid} alt="grid" className="side-icon" />}
              >
                <Link className="sub_links" to="/privacy">
                  Privacy & Policy
                </Link>
              </Menu.Item>
              <Menu.Item
                className="cstm-menu-item"
                key="13"
                icon={<img src={grid} alt="grid" className="side-icon" />}
              >
                <Link className="sub_links" to="/terms&conditions">
                  Terms & Conditions
                </Link>
              </Menu.Item>
            </Menu>
            <div className={styles.logout}>
              <img src={grid} alt="grid" />
              <p onClick={handleLogout} className={styles.link}>
                Logout
              </p>
            </div>
          </div>
        </div>
      </Sider>
      <Layout className="site-layout">
        <div className="layout-header">
          <p className="title">Welcome {user.fullName}</p>
          <p className="desc">Admin</p>
        </div>
        <Content>
          {isCards && (
            <Row gutter={24}>
              {data &&
                data.map((data, index) => {
                  // console.log(data,'my data')
                  return (
                    <Col span={7} key={index}>
                      <CategoryCard
                        title={data.title}
                        value={data.value}
                        desc={data.desc}
                        pin={data.pin}
                        location={data.location}
                        user={data.user}
                      />
                    </Col>
                  );
                })}
            </Row>
          )}
          <div className={styles.content}>
            <Row className={styles.row}>
              {currentPaths.map((path, ind, { length }) => {
                const isLast = ind === length - 1 && length > 1;
                if (isLast) {
                  return (
                    <div className={styles.active_div}>
                      <GoChevronRight className={styles.icon_right} />
                      <p className={styles.active}>{path}</p>
                    </div>
                  );
                } else if (ind !== 0 && length > 1) {
                  return (
                    <div className={styles.active_div}>
                      <GoChevronRight className={styles.icon_right} />
                      <p className={styles.heading}>{path}</p>
                    </div>
                  );
                }
                return <p className={styles.heading}>{path}</p>;
              })}
            </Row>
            <div>{children}</div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default PageLayout;
