import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../api";
import {
  setAllAdmins,
  asyncShowSuccess,
  asyncShowError,
  setPolicy,
  setUserActive,
  setUserFreeze,
  setConditions
} from "./UsersSlice";

export const asyncGetActiveUsers = createAsyncThunk(
  "getActiveUsers",
  async (_, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `superadmin/active-users`,
        token: getState().login.token,
      });
      // console.log(res)
      if (res.success) {
        dispatch(setUserActive(res.data));
        return res;
      }
      if (res.success === false) {
        return res;
      }
      return res;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncGetFreezeUsers = createAsyncThunk(
  "getFreezeUsers",
  async (_, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `superadmin/freeze-users`,
        token: getState().login.token,
      });
      console.log(res, "freeze");
      if (res.success) {
        dispatch(setUserFreeze(res.data));
        return res;
      }
      if (res.success === false) {
        return res;
      }
      return res;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncDeleteUserById = createAsyncThunk(
  "deleteUserById",
  async ({ id }, { getState, dispatch }) => {
    try {
      // console.log(id, "click ho rae")
      const res = await callApi({
        path: `superadmin/delete-user/${id}`,
        method: "DELETE",
        token: getState().login.token,
      });
      // console.log(res)
      if (res.success) {
        return res;
      }
      if (res.success === false) {
        return res;
      }
      return res;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const aysncUpdateUserStatus = createAsyncThunk(
  "updateUserStatus",
  async ({ id }, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `superadmin/user-status/${id}`,
        method: "PUT",
        token: getState().login.token,
      });
      if (res.success) {
        return res;
      }
      if (res.success === false) {
        return res;
      }
      return res;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncEmailToUser = createAsyncThunk(
  "emailToUser",
  async ({ body }, { getState, dispatch }) => {
    try {
      // console.log(body, "my body")
      const res = await callApi({
        path: `superadmin/email-to-user`,
        method: "POST",
        body,
        token: getState().login.token,
      });
      console.log(res);
      if (res.success) {
        dispatch(
          asyncShowSuccess({ message: "Email", description: res.message })
        );
        return res;
      }
      if (res.success === false) {
        dispatch(
          asyncShowError({ message: "Email", description: res.message })
        );
        return res;
      }
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({ message: "Email", description: error.message })
      );
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncGetAllAdmins = createAsyncThunk(
  "getAllAdmins",
  async (_, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `superadmin/admins`,
        token: getState().login.token,
      });
      console.log(res);
      if (res.success) {
        dispatch(setAllAdmins(res.data));
        return res;
      }
      if (res.success === false) {
        return res;
      }
      return res;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncUpdateAdminsProfile = createAsyncThunk(
  "updateAdminsProfile",
  async ({ id, body }, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `superadmin/update-admins/${id}`,
        method: "PUT",
        body,
        token: getState().login.token,
      });
      console.log(res);
      if (res.success) {
        return res;
      }
      if (res.success === false) {
        return res;
      }
      return res;
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncCreateAdmin = createAsyncThunk(
  "createAdmin",
  async ({ body }, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `superadmin/create-admin`,
        method: "POST",
        body,
        token: getState().login.token,
      });
      // console.log(res)
      if (res.success) {
        dispatch(
          asyncShowSuccess({
            message: "Create Admin",
            description: res.message,
          })
        );
        return res;
      }
      if (res.success === false) {
        dispatch(
          asyncShowError({ message: "Create Admin", description: res.message })
        );
        return res;
      }
      dispatch(
        asyncShowError({ message: "Create Admin", description: res.message })
      );
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({ message: "Create Admin", description: error.message })
      );
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncCreatePrivacyPolicy = createAsyncThunk(
  "createPrivacyPolicy",
  async ({ body }, { getState, dispatch }) => {
    try {
      // console.log(body, "user async body")
      const res = await callApi({
        path: `privacy`,
        method: "POST",
        body,
        token: getState().login.token,
      });
      if (res.success) {
        dispatch(
          asyncShowSuccess({
            message: "Privacy Policy",
            description: res.message,
          })
        );
        return res;
      }
      if (res.success === false) {
        dispatch(
          asyncShowError({
            message: "Privacy Policy",
            description: res.message,
          })
        );
        return res;
      }
      dispatch(
        asyncShowError({ message: "Privacy Policy", description: res.message })
      );
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "Privacy Policy",
          description: error.message,
        })
      );
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncGetPrivacyPolicy = createAsyncThunk(
  "getPrivatePolicy",
  async (_, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `privacy`,
        token: getState().login.token,
      });
      if (res.success) {
        dispatch(
          asyncShowSuccess({
            message: "Privacy Policy",
            description: res.message,
          })
        );
        dispatch(setPolicy(res.data));
        return res;
      }
      if (res.success === false) {
        dispatch(
          asyncShowError({
            message: "Privacy Policy",
            description: res.message,
          })
        );
        return res;
      }
      dispatch(
        asyncShowError({ message: "Privacy Policy", description: res.message })
      );
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "Privacy Policy",
          description: error.message,
        })
      );
      console.log(error);
      return { success: false };
    }
  }
);
export const asyncUpdatePrivacyPolicy = createAsyncThunk(
  "updatePrivacyPolicy",
  async ({ description, id }, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `privacy/${id}`,
        method: "PUT",
        body: { description },
        token: getState().login.token,
      });

      if (res.success) {
        dispatch(
          asyncShowSuccess({
            message: "Privacy Policy Updated",
            description: res.message,
          })
        );
        return res;
      }

      dispatch(
        asyncShowError({
          message: "Privacy Policy Updation Error",
          description: res.message,
        })
      );
      return { success: false };
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "Privacy Policy Updation Error",
          description: error.message,
        })
      );
      return { success: false };
    }
  }
);
export const asyncDeletePrivacyPolicy = createAsyncThunk(
  "deletePrivacyPolicy",
  async (_, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `privacy`,
        method: "DELETE",
        token: getState().login.token,
      });
      console.log(res);
      if (res.success) {
        dispatch(
          asyncShowSuccess({
            message: "Privacy Policy",
            description: res.message,
          })
        );
        return res;
      }
      if (res.success === false) {
        dispatch(
          asyncShowError({
            message: "Privacy Policy",
            description: res.message,
          })
        );
        return res;
      }
      dispatch(
        asyncShowError({ message: "Privacy Policy", description: res.message })
      );
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "Privacy Policy",
          description: error.message,
        })
      );
      console.log(error);
      return { success: false };
    }
  }
);

export const asyncCreateTermsAndConditions = createAsyncThunk(
  "createTerms&Conditions",
  async ({ body }, { getState, dispatch }) => {
    try {
      console.log(body, "user async body")
      const res = await callApi({
        path: `terms-conditions`,
        method: "POST",
        body,
        token: getState().login.token,
      });
      if (res.success) {
        dispatch(
          asyncShowSuccess({
            message: "Terms And Condtions",
            description: res.message,
          })
        );
        return res;
      }
      if (res.success === false) {
        dispatch(
          asyncShowError({
            message: "Terms And Condtions",
            description: res.message,
          })
        );
        return res;
      }
      dispatch(
        asyncShowError({ message: "Terms And Condtions", description: res.message })
      );
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "Terms And Condtions",
          description: error.message,
        })
      );
      console.log(error);
      return { success: false };
    }
  }
);

export const asyncGetTermsAndConditions = createAsyncThunk(
  "getTermsAndConditions",
  async (_, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `terms-conditions`,
        token: getState().login.token,
      });
      if (res.success) {
        dispatch(
          asyncShowSuccess({
            message: "Terms And Condtions",
            description: res.message,
          })
        );
        dispatch(setConditions(res.data));
        return res;
      }
      if (res.success === false) {
        dispatch(
          asyncShowError({
            message: "Terms And Condtions",
            description: res.message,
          })
        );
        return res;
      }
      dispatch(
        asyncShowError({ message: "Terms And Condtions", description: res.message })
      );
      return res;
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "Terms And Condtions",
          description: error.message,
        })
      );
      console.log(error);
      return { success: false };
    }
  }
);

export const asyncUpdateTermsAndConditions = createAsyncThunk(
  "updateTermsAndConditions",
  async ({ description, id }, { getState, dispatch }) => {
    try {
      const res = await callApi({
        path: `terms-conditions/${id}`,
        method: "PUT",
        body: { description },
        token: getState().login.token,
      });

      if (res.success) {
        dispatch(
          asyncShowSuccess({
            message: "Terms & Conditions Updated",
            description: res.message,
          })
        );
        return res;
      }

      dispatch(
        asyncShowError({
          message: "Terms & Conditions Updation Error",
          description: res.message,
        })
      );
      return { success: false };
    } catch (error) {
      dispatch(
        asyncShowError({
          message: "Terms & Conditions Updation Error",
          description: error.message,
        })
      );
      return { success: false };
    }
  }
);