import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import AllUsers from "../../pages/admin/allusers";
import CreateRole from "../../pages/admin/create";
import Emails from "../../pages/emails";
import Home from "../../pages/home";
import Login from "../../pages/login";
import Active from "../../pages/pins/active";
import HidePins from "../../pages/pins/hide";
import Privacy from "../../pages/privacy&policy";
import ActiveUser from "../../pages/users/active";
import FreezUser from "../../pages/users/freez";
import PageLayout from "../PageLayout";
import Advertisement from "../../pages/advertisement";
import AddAdvertisement from "../../pages/advertisement/add";
import PrivacyPolicy from "../../pages/static-pages/PrivacyPolicy_PinPlus.js";
import TermsConditions from "../../pages/terms&conditions";
const AppRouting = () => {
  const { token } = useSelector((s) => s.login);

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/login"
          element={!token ? <Login /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="*"
          element={token ? <HomeRoutes /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
};

const HomeRoutes = () => {
  return (
    <PageLayout>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/pins/active" element={<Active />} />
        <Route exact path="/pins/hide" element={<HidePins />} />
        <Route exact path="/users/active" element={<ActiveUser />} />
        <Route exact path="/users/freez" element={<FreezUser />} />
        <Route exact path="/admin/users" element={<AllUsers />} />
        <Route exact path="/admin/create" element={<CreateRole />} />
        <Route exact path="/emails" element={<Emails />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/terms&conditions" element={<TermsConditions />} />
        <Route exact path="/advertisement" element={<Advertisement />} />
        <Route exact path="/advertisement/add" element={<AddAdvertisement />} />
      </Routes>
    </PageLayout>
  );
};

export default AppRouting;
